import { styled } from '@mui/system'
import { Grid as MuiGrid } from '@mui/material'

const shouldForwardProp = (prop) => !['message', 'position'].includes(prop)

const Header = styled('header', { shouldForwardProp })`
	${({ message, position }) =>
		position === 'top' || !message
			? `
	//box-shadow: 0 0 54px 0 rgba(0, 0, 0, 0.09);
`
			: null}
`

const GridContainer = styled(MuiGrid)`
	padding: var(--spacing-2) var(--spacing-3);
	.mini-trigger {
		padding: 0;
		.MuiSvgIcon-root {
			font-size: ${({ theme }) => theme.typography.pxToRem(22)};
		}
	}
`

const GridItem = styled(MuiGrid)``

const GridItemCenter = styled(MuiGrid)``

const LogoWrapper = styled('div')`
	margin: 0 auto;
	max-width: 120px;
	width: 100%;
`

export { GridContainer, GridItem, GridItemCenter, Header, LogoWrapper }
